.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.subtitle {
  color: #10999E;
  font-size: 14px;
  padding: 8px 4vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #10999E;
  min-height: 30vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #DDDDDD;
  padding-left: 2vw;
  font-size: 6vw;
}
.logo {
  color: #DDDDDD;
  font-size: 10vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section:nth-child(2n+1) {
  background: #CDCDCD;
}
section {
  padding: 5vh 5vw;
}

.spotlight {
  display: flex;
  flex-wrap: wrap;
}

.spotlight div {
  min-width: 290px;
  flex: 50%;
  box-sizing: border-box;
}

.spotlight-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vw;
}

h1, h2, h3, h4, h5, h6, p {
  color: #10999E;
}

.spotlight-item p {
  min-width: 250px;
  color: #10999E;
}

i.spotlight-icon {
  font-size: 3rem;
  color: #10999E;
}


footer {
  background: #10999E;
  height: 5vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: #CDCDCD;
}